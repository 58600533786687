<template>
    <div class="flex-column" >
        <!-- 搜索 -->
        <sticky :className="'sub-navbar'">
            <div class="filter-container">
                <permission-btn moduleName='wmsGoodsChangeTbls' size="mini" v-on:btn-event="onBtnClicked"></permission-btn>
            </div>
        </sticky>
        <!-- 主表展示 -->
        <div class="app-container flex-item">
            <el-row :gutter="8" class="fh">
                <el-col :span="4" class="fh ls-border">
                    <el-card shadow="never" class="body-small fh" style="overflow: auto">
                        <!-- <div class="filter-container">
                            <el-input @keyup.enter.native="handleFilter" style="width:160px !important; " size="mini" class="filter-item top_search_css" :placeholder="'包装码'" v-model.trim="listQuery.ContainerBarcode" clearable> </el-input>
                            <el-button class="filter-item" size="mini" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
                        </div> -->
                        <el-tree    draggable  style="margin-top:10px"  :default-expand-all="true" :data="getData"  :props="defaultProps"  node-key="packCode"  @node-click="handleNodeClick"  highlight-current   >
                            <!-- <span class="span-ellipsis" slot-scope="{ node }">
                            <span :title="node.label">{{ node.label }}</span>
                        </span> -->
                        </el-tree>
                    </el-card>
                </el-col>
                <el-col :span="20" class="fh">
                <div class="bg-white fh">
                   已有模板：
                    <el-checkbox-group v-model="allNodeDetails" class="checktemplate">
                        <el-checkbox class="checktemplatechil" v-for="item in modelNode" :key="item.id" :label="item.dtCode" disabled> 
                            <p style="color:black">{{item.name}}</p>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                </el-col>
            </el-row>
            <el-dialog v-el-drag-dialog    class="dialog-mini" width="50%" :title="diaText[diastate]"  v-loading="$store.state.listLoading" :visible.sync="modelAddDia">
                 <el-checkbox-group v-model="checkModel" class="checktemplate">
                        <el-checkbox class="checktemplatechil" v-for="item in modelNode" :key="item.id" :label="item.dtCode" :disabled="item.dis"> 
                            <p>{{item.name}}</p>
                        </el-checkbox>
                </el-checkbox-group>
                <div  slot="footer">
                    <el-button size="small" type="danger" @click="modelAddDia=false">取消</el-button>
                    <el-button size="small" type="primary" v-if="diastate=='created'" @click="subModel">确定</el-button>
                    <el-button size="small" type="primary" v-if="diastate=='del'" @click="subdelModel">确定</el-button>
                </div>
            </el-dialog>
           
        </div>
    </div>
</template>
<script>
    import * as basicConfiguration from "@/api/basicConfiguration/basicConfiguration"; //基础配置模块
    import * as categorys from '@/api/categorys'
    import waves from '@/directive/waves' // 水波纹指令
    import Sticky from '@/components/Sticky'
    import permissionBtn from '@/components/PermissionBtn'
    import elDragDialog from '@/directive/el-dragDialog'
    import extend from "@/extensions/delRows.js"
    export default {
        name: 'wmsGoodsChangeTbl',
        components: { Sticky, permissionBtn },
        mixins: [extend],
        directives: {
            waves,
            elDragDialog
        },
         mounted() {
        },
        watch:{
        },
        data() {
            return {
                diaText:{created:"添加对应关系",del:"删除对应关系"},
                diastate:"created",
                defaultProps: {
                    children: 'childrens', 
                    label: 'name'
                },
                getData:[],
                modelAddDia:false,
                checkRow:{},
                modelNode:[],
                checkModel:[],
                allNodeDetails:[],//已有的明细

            }
        },
        computed: {
           
        },
        filters: {
        },
        created() {
        },
        activated() {
            this.getList()
            this.getPdaModel()
        },
        methods: {
            getPdaModel(){
                this.$store.commit("updataListLoading",true)
                categorys.getList({
                    page: 1,
                    limit: 9999,
                    TypeId: 'SYS_PdaModel'
                    }).then((res)=>{
                        if(res.code=='200'){
                            res.data.forEach((item)=>{
                                item.dis=false
                            })
                            this.modelNode=res.data
                        }
                        else{
                            this.$notify({
                                title: '失败',
                                message: '获取失败',
                                type: 'error',
                                duration: 2000
                            })
                        }
                        this.$store.commit("updataListLoading",false)
                    }).catch(()=>{
                         this.$notify({
                            title: '失败',
                            message: '获取失败',
                            type: 'error',
                            duration: 2000
                        })
                        this.$store.commit("updataListLoading",false)
                    })
            },
            // 删除提交
            subdelModel(){
                 let subData=[]
                this.checkModel.forEach((item)=>{
                    let ss={}
                    ss.modelId=this.checkRow.id
                    ss.typeCode=item
                    subData.push(ss)
                })
                this.$store.commit("updataListLoading",true)
                basicConfiguration.delModel(subData).then((res)=>{
                    if(res.code=='200'){
                         this.$notify({
                            title: '成功',
                            message: res.message,
                            type: 'success',
                            duration: 2000
                        })
                        this.$store.commit("updataListLoading",false)
                        this.modelAddDia=false
                        this.getList()
                    }else{
                        this.$notify({
                            title: '失败',
                            message: '删除失败',
                            type: 'error',
                            duration: 2000
                        })
                        this.$store.commit("updataListLoading",false)
                    }
                }).catch(()=>{
                     this.$notify({
                        title: '失败',
                        message: '删除失败',
                        type: 'error',
                        duration: 2000
                    })
                    this.$store.commit("updataListLoading",false)
                })
            },
            //添加提交
            subModel(){
                let subData=[]
                this.checkModel.forEach((item)=>{
                    let ss={}
                    ss.modelId=this.checkRow.id
                    ss.typeCode=item
                    subData.push(ss)
                })
                this.$store.commit("updataListLoading",true)
                basicConfiguration.addModel(subData).then((res)=>{
                    if(res.code=='200'){
                         this.$notify({
                            title: '成功',
                            message: res.message,
                            type: 'success',
                            duration: 2000
                        })
                        this.$store.commit("updataListLoading",false)
                        this.modelAddDia=false
                        this.getList()
                    }else{
                        this.$notify({
                            title: '失败',
                            message: '添加失败',
                            type: 'error',
                            duration: 2000
                        })
                        this.$store.commit("updataListLoading",false)
                    }
                }).catch(()=>{
                     this.$notify({
                        title: '失败',
                        message: '添加失败',
                        type: 'error',
                        duration: 2000
                    })
                    this.$store.commit("updataListLoading",false)
                })
            },
            onBtnClicked: function(domId) {
                console.log('you click:' + domId)
                switch (domId) {
                    case 'btnAdd':  
                        if(this.checkRow.url==""||this.checkRow.url==null){
                            this.$message.error("请选择正确的节点")
                            return
                        }
                        this.checkModel=[]
                        if(this.modelNode.length>0){
                            this.diastate="created"
                            this.modelNode.forEach((item)=>{
                                item.dis=false
                            })
                            this.modelNode.forEach((item)=>{
                                this.allNodeDetails.forEach((u)=>{
                                    if(u==item.dtCode){
                                        item.dis=true
                                    }
                                })
                            })
                            this.modelAddDia=true
                        }else{
                            this.$message.error("获取pda模板失败")
                            return
                        }
                        break
                    case 'btnDel':  
                             if(this.checkRow.url==""||this.checkRow.url==null){
                                this.$message.error("请选择正确的节点")
                                return
                            }
                             this.checkModel=[]
                            if(this.modelNode.length>0){
                                this.modelAddDia=true
                                this.diastate="del"
                                this.modelNode.forEach((item)=>{
                                    item.dis=false
                                })
                                this.modelNode.forEach((item)=>{
                                    this.allNodeDetails.forEach((u)=>{
                                        if(u==item.dtCode){
                                            item.dis=true
                                        }
                                    })
                                })
                                this.modelNode.forEach((item)=>{
                                    item.dis=!item.dis
                                })
                            }else{
                                this.$message.error("获取pda模板失败")
                            }
                        break
                    default:
                        break
                }
            },
           handleNodeClick(data) {
               this.checkRow=data
               this.allNodeDetails=[]
               if(data.url!=''&&data.url!=null){
                   this.$store.commit("updataListLoading",true)
                    basicConfiguration.getModulesDetail({modelId:data.id}).then((res)=>{
                    if(res.code=='200'){
                        res.result.forEach((item)=>{
                            this.allNodeDetails.push(item.typeCode)
                        })
                        this.$store.commit("updataListLoading",false)
                    }else{
                        this.$notify({
                            title: '失败',
                            message: '获取失败',
                            type: 'error',
                            duration: 2000
                        })
                        this.dataList=[]
                        this.$store.commit("updataListLoading",false)
                    }
                }).catch(()=>{
                    this.$notify({
                            title: '失败',
                            message: '获取失败',
                            type: 'error',
                            duration: 2000
                        })
                    this.dataList=[]
                    this.$store.commit("updataListLoading",false)
                })
               }
               
            },
            getList() {
                // this.$store.commit("updataListLoading",true)
                basicConfiguration.getModulesPDA().then((res) => {
                    if(res.code=='200'){
                        this.$store.commit("updataListLoading",false)
                        this.getData=res.result
                        if(this.getData.length>0){
                            this.$nextTick(() => {
                                // document.querySelector('.el-tree-node__content').click()
                                let mydiv=document.querySelectorAll('.el-tree-node__content');
                                mydiv.forEach((item,index)=>{
                                    if(index==1){
                                        item.click()
                                    }
                                })
                            })
                        }
                    }else{
                        // this.$store.commit("updataListLoading",false)
                        this.$notify({
                            title: '失败',
                            message: '查询失败',
                            type: 'error',
                            duration: 2000
                        })
                    }
                }).catch(()=>{
                    // this.$store.commit("updataListLoading",false)
                    this.$notify({
                        title: '失败',
                        message: '查询失败',
                        type: 'error',
                        duration: 2000
                    })
                })
            },
        }
    }
</script>
<style  lang="scss" scoped>
    .checktemplatechil:first-child{
       margin-left:30px ;
    }
    .el-checkbox {
        width: 55px;
    }
    .dialog-mini .el-select{
        width:100%;
    }
    .fenge{
        margin-right: 10px;
    }
    .top_search_csss{
        height: 30px;
        line-height: 30px;
        width: 150px !important;
        margin-right:10px;
        
    }
    .diasearch{
        height: 50px;
        line-height: 50px;
        position: relative;
        width: 100%;
        text-align: right;
        padding-right: 20px;
        // transition: 600ms ease position;
    }
    .span-ellipsis{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .el-table__header-wrapper  .el-checkbox{//找到表头那一行，然后把里面的复选框隐藏掉
        display:none
    }
    
    
</style>
